import { Typography, Layout, Row, Col, Affix, Image, Menu } from "antd";

import React, { useState, useContext, useEffect } from "react";

import { Context } from "./store/Context";
import ConsultantSearch from "./ConsultantSearch";
import DesktopAppView from "./DesktopAppView";
import { AppstoreOutlined, ContactsOutlined } from "@ant-design/icons";

const items = [
  {
    label: (
      <a href="/" rel="noopener noreferrer">
        Consultant Service
      </a>
    ),
    key: "consultant",
    icon: <ContactsOutlined />,
  },
  {
    label: (
      <a href="/app" rel="noopener noreferrer">
        Meowow App
      </a>
    ),
    key: "app",
    icon: <AppstoreOutlined />,
  },
];

function Desktop() {
  const { state } = useContext(Context);

  const [current, setCurrent] = useState(() =>
    window.location.pathname === "/" ? "consultant" : "other"
  );

  useEffect(() => {
    if (window.location.pathname === "/") {
      setCurrent("consultant");
    } else if (window.location.pathname === "/app") {
      setCurrent("app");
    }
  }, []);

  const onClick = (e) => {
    console.log("click ", e);
    if (window.location.pathname === "/") {
      setCurrent("consultant");
    }
  };

  return (
    <Layout style={{ minWidth: "1000px" }}>
      <Affix offsetTop={0}>
        <Layout.Header style={{ background: "white", height: "70px" }}>
          <Row
            justify="center"
            align="top"
            style={{ backgroundColor: "white", height: "100%" }}
          >
            <Row
              justify="start"
              align="top"
              style={{
                maxWidth: "2000px",
                width: "100%",
                height: "100%",
                backgroundColor: "white",
              }}
            >
              <Col
                offset={1}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                <Row justify="center" align="bottom">
                  <Col>
                    <Image
                      height={30}
                      preview={false}
                      src={state.appLogo}
                    ></Image>
                  </Col>
                  <Col>
                    <Typography.Title
                      level={3}
                      style={{ color: "black", marginLeft: "10px" }}
                    >
                      {state.appName}
                    </Typography.Title>
                  </Col>

                  <Menu
                    style={{ marginLeft: "20px" }}
                    onClick={onClick}
                    selectedKeys={[current]}
                    mode="horizontal"
                    items={items}
                  />
                </Row>
              </Col>
            </Row>
          </Row>
        </Layout.Header>
      </Affix>

      <Layout.Content>
        {window.location.pathname === "/" ? (
          <ConsultantSearch />
        ) : (
          <DesktopAppView />
        )}
      </Layout.Content>
    </Layout>
  );
}

export default Desktop;
