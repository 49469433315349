import React from "react";

import appLogo from "../images/meowow.png";

import appleStoreBadge from "../images/apple_store_badge.svg";
import googlePlayBadge from "../images/google_play_badge.png";

import coverImage from "../images/landingPage_6.png";

// endorsement images

// section images
import translate_screen from "../images/landingPage_1.png";
import music_screen from "../images/landingPage_2.png";
import accurate_screen from "../images/landingPage_3.png";
import journal_screen from "../images/landingPage_4.png";
import greeting_screen from "../images/landingPage_5.png";

import instagramImage from "../images/instagram.png";

export const initialState = {
  // when in dev, change appURL to local url
  // appURL: 'http://localhost:3000',
  // when in production, change appURL to real url
  appURL: "https://gather-tomorrow.web.app",

  appLogo: appLogo,
  appName: "Meowow",

  coverTitle: "All-in-One App for Your Cat's Needs",
  coverText:
    "The ultimate app for cat owners and their pets—understand your cat's mood, document their adventures, and create the perfect environment with Purrfect Companion.",
  appleStoreBadge: appleStoreBadge,
  appleStoreLink: "https://apps.apple.com/us/app/meowow/id6737744844",
  googlePlayBadge: googlePlayBadge,
  googlePlayLink: "",

  coverImage: coverImage,

  endorsementTitle: `Understand Your Cat Like Never Before`,
  endorsementText: `Ever wondered what your cat is really trying to tell you? Our AI-driven app translates your cat's sounds into human language, giving you an insight into their thoughts and feelings.`,
  //   endorsementList: [
  //     {
  //       title: `DreamHub: Visualized Stories`,
  //       titleColor: `orangeRed`,
  //       image: dreamhub_filled,
  //       URL: `https://dreamhub.app`,
  //     },
  //     {
  //       title: `VoiceQnA: Speak a New Language`,
  //       titleColor: `forestGreen`,
  //       image: voiceqna_filled,
  //       URL: `https://voiceqna.com`,
  //     },
  //     {
  //       title: `VoiceMirror: Travel Translator`,
  //       titleColor: `blue`,
  //       image: voicemirror_filled,
  //       URL: `https://voiceqna.com/mirror`,
  //     },
  //     {
  //       title: `BaZiPaiPai: Know Your Destiny`,
  //       titleColor: `black`,
  //       image: bazipaipai_unfilled,
  //       URL: `https://bazipaipai.com`,
  //     },
  //     {
  //       title: `SpindriftHome: HOA Management`,
  //       titleColor: `orangeRed`,
  //       image: spindrifthome_filled,
  //       URL: `https://spindrifthome.com`,
  //     },
  //   ],

  sectionList: [
    {
      title: `Your Cat's Voice, Now Decoded!`,
      text: `Unlock the secret language of your feline companion with our innovative AI-powered app! With cutting-edge sound translation technology, you'll finally understand what your cat is saying – from their happy purrs to their curious meows.`,
      image: translate_screen,
    },
    {
      title: `Precision You Can Trust: The Most Accurate Cat Sound Translator`,
      text: `Our AI-powered translation technology uses advanced machine learning algorithms to deliver unprecedented accuracy in understanding your cat's meows, purrs, and other vocalizations. From distressed meows to discomforted growls, our app helps you detect when your cat is feeling unwell or uncomfortable, so you can take action right away.`,
      image: accurate_screen,
    },
    {
      title: `Create a Cherished Diary of Your Cat's Life`,
      text: `Capture every precious moment with your cat in a beautifully organized digital diary. With our easy-to-use journal feature, you can upload daily photos of your furry friend and add personal notes about their activities, moods, and any special events.`,
      image: journal_screen,
    },
    {
      title: `Soothing Sounds: Help Your Cat Relax and Unwind`,
      text: `Calm your cat’s mind with a selection of specially curated music designed to reduce anxiety and promote relaxation. Whether your feline friend is feeling stressed, restless, or just needs a moment of peace, our app offers a variety of gentle, calming melodies scientifically crafted to soothe their nerves.`,
      image: music_screen,
    },
    {
      title: `Download Now and Start Connecting with Your Cat!`,
      text: `The future of cat communication, relaxation, and care is here! Don’t miss out on understanding your cat’s needs and emotions like never before.`,
      image: greeting_screen,
    },
  ],

  instagramImage: instagramImage,
  instagramLink: "https://www.instagram.com/meowow_cat/",
};

const initialContext = {
  state: initialState,
  dispatch: () => null,
};

export const Context = React.createContext(initialContext);
