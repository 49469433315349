import React, { useState } from "react";
import { Typography, Card, Button, Row, Col, Space, Input, Image } from "antd";
import { GlobalOutlined, ContactsOutlined } from "@ant-design/icons";
import coverImage from "../src/images/web_landing.png";

const { TextArea } = Input;

const consultantImages = {
  0: require("../src/images/consultants/id_0.png"),
  1: require("../src/images/consultants/id_1.png"),
  2: require("../src/images/consultants/id_2.png"),
  6: require("../src/images/consultants/id_6.png"),
  7: require("../src/images/consultants/id_7.png"),
  8: require("../src/images/consultants/id_8.png"),
  9: require("../src/images/consultants/id_9.png"),
  10: require("../src/images/consultants/id_10.png"),
  11: require("../src/images/consultants/id_11.png"),
  12: require("../src/images/consultants/id_12.png"),
  13: require("../src/images/consultants/id_13.png"),
  14: require("../src/images/consultants/id_14.png"),
  16: require("../src/images/consultants/id_16.png"),
  17: require("../src/images/consultants/id_17.png"),
  20: require("../src/images/consultants/id_20.png"),
  21: require("../src/images/consultants/id_21.png"),
  26: require("../src/images/consultants/id_26.png"),
  27: require("../src/images/consultants/id_27.png"),
  28: require("../src/images/consultants/id_28.png"),
  29: require("../src/images/consultants/id_29.png"),
  30: require("../src/images/consultants/id_30.png"),
  31: require("../src/images/consultants/id_31.png"),
  32: require("../src/images/consultants/id_32.png"),
};

const { Title, Paragraph, Text } = Typography;
const ConsultantSearch = () => {
  // Sample consultant data
  const consultants = [
    {
      id: 0,
      name: "Kristiina Wilson",
      description:
        "Kristiina Wilson, MA, CCBC, is a feline behavior expert with over 20 years of experience in socializing, fostering, and rehoming cats. She owns Cattitude Adjustment and has placed over 400 abandoned cats in homes. Her research focuses on animal behavior, including shelter stress studies. She has worked with cheetahs, dolphins, and birds and consults for Cat Person and The Wildest.",
      expertArea: "cat",
      website: "https://www.instinctdogtraining.com/",
      phone: "917-371-7280",
      picture: "/src/images/consultants/id_0",
    },
    {
      id: 1,
      name: "Jessica Schulte",
      description:
        "Jessica is the Director of Behavior & Training at Instinct East Harlem, where she oversees training programs and provides consultations for clients. A certified dog behavior consultant and trainer, she specializes in addressing complex behavior issues such as reactivity, fear, aggression, and anxiety, while also focusing on life skills, manners, and puppy development. Jessica is a Certifying Official for NACSW K9 Nosework trials and ORTs and has experience in agility, competition obedience, Rally-O, and K9 Nose Work. She holds an MFA in non-fiction writing from Columbia University and has completed the IAABC Principles & Practice course. Jessica lives in NYC with her husband, Bruce, and their dog, Trix, a Rat Terrier mix.",
      expertArea: "dog",
      website: "https://www.instinctdogtraining.com/",
      phone: "646-334-0650",
      picture: "/src/images/consultants/id_1",
    },
    {
      id: 2,
      name: "Mary Molloy",
      description:
        "Mary Molloy is a Certified Cat Behavior Consultant, Certified Professional Dog Trainer (Knowledge Assessed), and a Fear Free Certified Professional Trainer. She runs her own business and has also worked per diem with the Animal Rescue Center and the Behavior Department at the ASPCA. Mary has collaborated with Veterinary Behaviorists to assist pets with complex behavioral issues.",
      expertArea: "all",
      website: "http://www.nirvanatails.com",
      phone: "917-710-2849",
      picture: "/src/images/consultants/id_2",
    },
    {
      id: 6,
      name: "Amanda Gagnon",
      description:
        "Amanda Gagnon, MA, is an anthrozoologist, dog behavior consultant, and trainer with 15+ years of experience. She blends science and cross-species connection in her training approach. Certified by IAABC and CCPDT, she has worked with rescues, shelters, and media outlets like Good Morning America. She leads Amanda Gagnon Dog Training, offering classes, workshops, and research on dog-human relationships.",
      expertArea: "dog",
      website: "http://www.amandagagnondogtraining.com/",
      phone: "540-327-3288",
      picture: "/src/images/consultants/id_6",
    },
    {
      id: 7,
      name: "Lisa Edwards",
      description:
        "Lisa J. Edwards, CDBC, CPDT-KA, is a best-selling author and dog behavior consultant since 2000. She emphasizes balanced training through boundaries, requested behaviors, and freedoms. She runs Three Dogs Training and specializes in fostering safe, happy relationships between dogs and families.",
      expertArea: "dog",
      website: "http://www.threedogstraining.com",
      phone: "845-228-2546",
      picture: "/src/images/consultants/id_7",
    },
    {
      id: 8,
      name: "Brian Burton",
      description:
        "Brian Burton is the Co-CEO of Instinct Dog Training, Inc. and Co-Founder of Instinct Dog Behavior & Training. He manages business development, financial affairs, franchise operations, and technology support systems for the company. Brian holds a Master's in Animal Behavior & Conservation from Hunter College and a Computer Science degree from Dalhousie University. He is a Certified Dog Behavior Consultant and a Karen Pryor Academy Certified Training Partner, specializing in severe aggression, fear, and anxiety in dogs. Brian has been featured in media such as The New York Times, NPR, and Sirius XM. He lives in Bronxville, NY, with Co-Founder Sarah Fraser and their four rescue dogs.",
      expertArea: "dog",
      website: "http://www.instinctdogtraining.com/",
      phone: "646-315-4897",
      picture: "/src/images/consultants/id_8",
    },
    {
      id: 9,
      name: "Sarah Fraser",
      description:
        "Sarah Fraser is the Co-CEO of Instinct Dog Training, Inc. and Co-Founder of Instinct Dog Behavior & Training. She oversees behavior programming for all locations and the Instinct Online School, while also managing training policies, marketing, communications, and brand management. Sarah holds a Master's in Animal Behavior & Conservation from Hunter College and is a Certified Dog Behavior Consultant, Certified Family Dog Mediator, Certified Professional Dog Trainer, and Karen Pryor Academy Certified Training Partner. She has worked with numerous NYC dogs and owners facing severe aggression, fear, and anxiety. Sarah has been featured in media outlets such as The New York Times, NBC Nightly News, and Martha Stewart. She lives in NYC with Co-Founder Brian Burton, their four rescue dogs, and a senior tuxedo cat.",
      expertArea: "dog",
      website: "http://www.instinctdogtraining.com",
      phone: "212-828-3647",
      picture: "/src/images/consultants/id_9",
    },
    {
      id: 10,
      name: "Linda Keehn",
      description:
        "Linda believe dog training and behavior modification should be safe, effective, and enjoyable for everyone involved. I am passionate about helping people live better with their dogs. My expertise includes advanced training and certifications in Separation Anxiety, new puppy integration, Aggression, Fears and Phobias, and helping families with Baby/Child dog issues, including introducing dogs to newborns, toddlers, or small children. I provide honest, practical advice and behavior modification as needed. Additionally, I am a Therapy Dog trainer and evaluator, as well as an Atlas Assistance Dog Certified Service Dog Trainer. I am committed to continually expanding my knowledge, gaining new tools and techniques to benefit both dogs and their families. While I deeply love working with dogs, I also find great joy in supporting their families.",
      expertArea: "dog",
      website: "https://www.positivecaninetraining.com/",
      phone: "917-865-2609",
      picture: "/src/images/consultants/id_10",
    },
    {
      id: 11,
      name: "Anthony De Marinis",
      description:
        "Anthony De Marinis specializes in working with dogs with behavior issues and high energy. He offers in-home behavior consultations and training services on Long Island, NY, as well as Online Virtual Consultations for clients outside the area. Anthony is a Certified Dog Behavior Consultant (CDBC), Accredited Dog Trainer (ADT), Licensed Family Dog Mediator (LFDM), and a Fear Free Certified Training Professional (FFCP). He is passionate about working with Working & Sport bred dogs and is currently competing in agility and sheep herding with his own Australian Kelpies, Journey and Quest. Anthony cohosts *The Canine Classroom* podcast, focusing on dog training and behavior modification. He is dedicated to continuous learning, attending seminars, and collaborating with other professionals. To learn more about Anthony's services, visit his website or check him out on social media!",
      expertArea: "dog",
      website: "https://www.demarinisdogtraining.com",
      phone: "516-582-7877",
      picture: "/src/images/consultants/id_11",
    },
    {
      id: 12,
      name: "Michelle Belio",
      description:
        "Michelle Belio is the Head Trainer and Founder of Pawsitively Waggin' Academy, with certifications as a Certified Dog Behavior Consultant (CDBC) and Certified Professional Dog Trainer (CPDT-KA). She holds a BA in Psychology and an MA in Animal Behavior and Conservation (2022) from Hunter College. Michelle specializes in force-free, positive reinforcement dog training, which is scientifically supported for safe and effective results. Her favorite dogs to work with are those with fear-based issues, helping them transform from anxiety to stress-free living. In her spare time, Michelle enjoys hiking in the Northeast and competing with her Border Collies, Arya and Rainier, in various trials including Disc, Dock Diving, Rally, Scent Work, Agility, and Trick Dog.",
      expertArea: "dog",
      website: "http://www.PawsitivelyWagginAcademy.com",
      phone: "917-407-2553",
      picture: "/src/images/consultants/id_12",
    },
    {
      id: 13,
      name: "Kate Naito",
      description:
        "Kate, based in Connecticut, began her career as a dog behavior consultant and trainer with the renowned Doggie Academy in Brooklyn, NY, in 2013. She is fully certified by both IAABC and CCPDT and continues her education to stay current with the latest methods and research. Before becoming a trainer, Kate was an instructor at the City University of New York. She now runs High Ten Dog Training, which she opened in 2021.",
      expertArea: "dog",
      website: "https://www.hightendogtraining.com/",
      phone: "kate@hightendogtraining.com",
      picture: "/src/images/consultants/id_13",
    },
    {
      id: 14,
      name: "Ellen Rassiger",
      description:
        "Ellen Rassiger is a professional dog trainer and behavior consultant with over 27 years of experience on Long Island. She graduated with distinction from the Karen Pryor Academy and holds CBCC-KA certification. Ellen specializes in dog bite cases, particularly those involving children. Through her business, Happy Dog Training & Behavior, she offers Reactive Dog Consultations, Resource Guarding Consultations, and fun, reward-based training classes, ranging from Puppy Kindergarten to advanced skills for adult dogs.",
      expertArea: "dog",
      website: "http://www.happydogbehavior.com",
      phone: "631-424-3647",
      picture: "/src/images/consultants/id_14",
    },
    {
      id: 16,
      name: "Aubrey Sancho",
      description:
        "Aubrey has extensive professional experience working with a variety of species, including horses, pigs, rats, exotic animals, and dogs. Her canine experience includes volunteering at a dog shelter in Hartford, interning as a Canine Sanctuary Caregiver, and managing a kennel at a private facility. While earning her Bachelor's of Science in Animal Science from the University of Connecticut, she became known for training pigs to sit and come when called. After graduation, Aubrey worked as a dog trainer at a private training facility in Manhattan, where she conducted private consultations and group puppy classes. Clients value Aubrey's passion, positivity, and her deep understanding of the scientific principles behind animal behavior.",
      expertArea: "dog",
      website: "https://clickwithyourcanine.com",
      phone: "203-224-9771",
      picture: "/src/images/consultants/id_16",
    },
    {
      id: 17,
      name: "Nora Moran",
      description:
        "Nora Moran is a leader in dog training with over 16 years of experience in the non-profit service dog industry. She is a Certified Personal Dog Trainer (CCPDT-KSA) and a Certified Separation Anxiety Trainer (CSAT), specializing in helping dog owners tackle separation anxiety. Nora uses a personalized approach to training, utilizing humane methods to create tailored plans for each dog, supported by data-tracking and one-on-one coaching. Her goal is to help dogs overcome anxiety and behavioral issues, improving their quality of life and strengthening their bond with their guardians. Outside of training, Nora enjoys hiking and trail running with her dogs, Dakota and Cheerio.",
      expertArea: "dog",
      website: "http://www.likeadogbce.com",
      phone: "845-834-9287",
      picture: "/src/images/consultants/id_17",
    },
    {
      id: 20,
      name: "Michelle Formato",
      description:
        "Michelle is a lifelong dog lover who pursued a career in dog training after college. Since 2018, she has worked in shelters and dog daycares, further developing her skills through an apprenticeship with Amanda Gagnon, a student of Ian Dunbar. After completing the apprenticeship, Michelle joined Amanda's team and now offers private training and group classes in Manhattan. In addition to her IAABC accreditation, she is certified as a CPDT-KA by the Certification Council for Professional Dog Trainers. Michelle focuses on teaching basic manners and behavior modification in an urban setting using positive reinforcement techniques.",
      expertArea: "dog",
      website: "https://www.amandagagnondogtraining.com/",
      phone: "michelle@amandagagnondogtraining.com",
      picture: "/src/images/consultants/id_20",
    },
    {
      id: 21,
      name: "Pam Dennison",
      description:
        "Pam Dennison, CDBC, CWRI, is a Certified Dog Behavior Consultant with the IAABC and a Certified Whistle Recall Instructor. She is a member of the Dog Writers Association of America (DWAA), The Pet Professional Guild, Truly Dog Friendly, and has been involved with NADOI and the Association of Professional Dog Trainers. Pam has written for numerous local, national, and international magazines, with many of her books and articles winning or being nominated for awards by the DWAA. In 1996, she founded Positive Dogs, where she has since helped thousands of dogs and handlers improve their relationships and solve behavioral problems. Pam offers a variety of classes, including puppy kindergarten, basic obedience, competition obedience, Rally-O, and seminars for aggressive dogs.",
      expertArea: "dog",
      website: "http://www.pamdennison.com",
      phone: "908-459-5244",
      picture: "/src/images/consultants/id_21",
    },
    {
      id: 26,
      name: "Elizabeth H Robinson",
      description:
        "Kizz Robinson founded 2B Dog Training in 2014, based in Clinton Hill, Brooklyn. She offers training sessions both in-client homes and in local parks, as well as virtual sessions for families worldwide. Kizz volunteered with the NYC Department of Corrections' Rikers Rovers Program and became a lead trainer at PumpkinPups Dog Training in 2015. She teaches various classes from Puppy Essentials to Manners 3 and co-leads the PumpkinPups Pros membership program. Kizz specializes in working with reactive small dogs, teaching families with children, trick training, and complex behavioral cases. She became a Certified Professional Dog Trainer (CPDT-KA) in 2016, a Certified Dog Behavior Consultant (CDBC) in 2020, and a Family Dog Mediator in 2023. Kizz lives with her cat, Legs, and her mixed-breed dog, Watson.",
      expertArea: "dog",
      website: "https://www.2bdogtraining.com/",
      phone: "917-364-7644",
      picture: "/src/images/consultants/id_26",
    },
    {
      id: 27,
      name: "Viviane Arzoumanian",
      description:
        "Viviane is a Licensed Family Dog Mediator-T, CDBC, CPDT-KA, CBATI, and PMCT2, with over 15 years of experience in dog training and behavior consultations. She specializes in coaching humans and guiding both professionals and pet parents through dog-related challenges. Viviane emphasizes collaboration, using her extensive network to find the best resources for her clients. Her services include case reviews, referrals to other professionals, and discussions on quality of life, including humane euthanasia when necessary. Viviane continues to work with dogs and their families from puppyhood to old age, offering a supportive and knowledgeable environment for all aspects of dog care and behavior.",
      expertArea: "dog",
      website: "https://www.pumpkinpups.com/",
      phone: "646-727-0992",
      picture: "/src/images/consultants/id_27",
    },
    {
      id: 28,
      name: "Lauren Jones Wenzel",
      description:
        "Lauren is a certified dog behavior consultant at Instinct Englewood with over 13 years of experience in training dogs. She specializes in behavior modification for fear, aggression, and anxiety, as well as basic obedience and puppy development. Lauren has taught various private and group classes, including Puppy Basics, Obedience, Canine Good Citizen Test, Therapy Dog Prep, and Rally Obedience. She volunteered at Liberty Humane Society, helping train and rehabilitate shelter dogs and teach new volunteers. Lauren has earned multiple titles in Rally Obedience and Canine Scentwork with her dogs. She holds a BFA in Theatre from Hofstra University and lives in Pennsylvania with her husband, David, their dogs Dori, Banzai!, Georgia, and their cat Viola.",
      expertArea: "dog",
      website:
        "https://www.instinctdogtraining.com/personnel/lauren-jones-wenzel/",
      phone: "201-431-7610",
      picture: "/src/images/consultants/id_28",
    },
    {
      id: 29,
      name: "Megan Byrne",
      description:
        "Meg is a Certified Dog Behavior Consultant at Instinct Englewood, offering consultations, private coaching, and home school programs. She specializes in addressing complex behavior issues like reactivity, fear, aggression, and impulsivity, while also focusing on life skills, manners, and puppy development. Before joining Instinct, Meg was the Director at St. Hubert's, where she taught private lessons, group classes, and developed coursework for reactive and aggressive dogs. She holds a Bachelor's Degree in Psychology from Rutgers University, where she graduated Magna Cum Laude. Meg lives in New Jersey with her two dogs, Luna (a Bull Terrier) and Kiwi (an Australian Kelpie).",
      expertArea: "dog",
      website: "https://www.instinctdogtraining.com/personnel/meg-byrne/",
      phone: "201-627-0066",
      picture: "/src/images/consultants/id_29",
    },
    {
      id: 30,
      name: "Cydne Mayberry",
      description:
        "Cydne is an IAABC-accredited trainer with over 10 years of experience in the pet services profession. She uses a science-based, human-first approach to dog training, focusing on empowering owners to improve their relationships with their dogs and future pets. Cydne works with a variety of cases, including resource guarding, separation anxiety, timid dogs, new puppy basics, and behavioral issues in elderly dogs. She has a particular passion for helping owners with leash reactivity. Cydne shares her life with Smokey, a Toto look-alike super-mutt, and Prince Philip Marlowe, a dominant cat who enjoys training sessions.",
      expertArea: "dog",
      website: "https://mayberrysmutts.wixsite.com/training",
      phone: "cydne.mayberry@gmail.com",
      picture: "/src/images/consultants/id_30",
    },
    {
      id: 31,
      name: "Ilana Riggi",
      description:
        "Ilana is a certified dog trainer through The International Association of Applied Behavior Consultants. She is passionate about helping dogs and cats build confidence and improve their quality of life by learning new skills, fostering positive connections with their owners and pet siblings. Ilana's interest in training began when her dog, Lailah, developed fears and reactivity around other dogs. Through reward-based training, Ilana helped Lailah gain confidence and reduce stress in their urban environment. Ilana is also an All Dogs Parkour Certified Instructor, incorporating parkour into private sessions to teach focus, impulse control, leash walking, and recall. She teaches Puppy Agility, Agility Skills levels 1-3, and Parkour in Central Park.",
      expertArea: "dog",
      website: "https://www.ilanaspetcare.com/training",
      phone: "ilanariggi@gmail.com",
      picture: "/src/images/consultants/id_31",
    },
    {
      id: 32,
      name: "Rachel Heide",
      description:
        "Rachel is an experienced dog trainer who has dedicated her career to working with dogs of all breeds, ages, sizes, and temperaments. She is IAABC accredited and has completed the Aggression in Dogs Mastercourse by Michael Shikashio, as well as being Fear Free Shelter Certified. Rachel is a LIMA-based trainer, starting all training with positive reinforcement methods. She focuses on building strong bonds between humans and dogs, believing that a solid relationship and understanding of basic obedience are the foundation for a dog to become a model citizen. Her approach aims to change a dog's behavior by altering how they perceive different situations.",
      expertArea: "dog",
      website: "https://mindfulk9solutions.com/",
      phone: "516-317-5960",
      picture: "/src/images/consultants/id_32",
    },
  ];

  const [filter, setFilter] = useState("all");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 850);

  // Filter consultants based on selection
  const filteredConsultants = consultants.filter(
    (consultant) =>
      filter === "all" ||
      consultant.expertArea === filter ||
      consultant.expertArea === "all"
  );

  // Handle window resize
  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 850);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const cardStyle = {
    height: "500px", // Set a fixed height for all cards
    overflow: "hidden", // Hide content overflow outside the card
  };

  return (
    <>
      {/* Introduction Section */}
      <Row
        justify="center"
        align="middle"
        style={{ backgroundColor: "white", paddingBottom: "50px" }}
      >
        <Col span={isMobile ? 20 : 12}>
          <Typography>
            <Title level={4}>Welcome to Meowow,</Title>
            <Title level={5}>Connecting You with Animal Behavior Experts</Title>
            <Paragraph>
              At Meowow, we're dedicated to helping pet owners better understand
              their furry companions. Whether you have questions about your
              dog's training or your cat's mysterious behaviors, our platform
              connects you with certified experts who can provide professional
              guidance.
            </Paragraph>
            <Paragraph>
              Our recommended consultants are all certified by the{" "}
              <Text strong>
                International Association of Animal Behavior Consultants (
                <a
                  href="https://iaabc.org/en/about-the-iaabc"
                  target="_blank"
                  rel="noreferrer"
                >
                  IAABC
                </a>
                )
              </Text>
              , a globally recognized organization committed to ethical,
              science-based animal behavior consulting and training.
            </Paragraph>
          </Typography>
        </Col>

        <Col span={isMobile ? 24 : 6}>
          <Row justify="center">
            <Image
              width={300}
              height={300}
              preview={false}
              src={coverImage}
            ></Image>
          </Row>
        </Col>
      </Row>

      {/* Consultant Grid */}
      <Row
        gutter={[12, 12]}
        justify="center"
        align="middle"
        style={{ marginTop: "10px" }}
      >
        {/* Filter buttons */}
        <Col span={24} justify="center" align="middle">
          <Space className="mb-6">
            <Button
              type={filter === "all" ? "primary" : "default"}
              onClick={() => setFilter("all")}
            >
              All
            </Button>
            <Button
              type={filter === "dog" ? "primary" : "default"}
              onClick={() => setFilter("dog")}
            >
              Dogs
            </Button>
            <Button
              type={filter === "cat" ? "primary" : "default"}
              onClick={() => setFilter("cat")}
            >
              Cats
            </Button>
          </Space>
        </Col>

        {filteredConsultants.map((consultant) => (
          <Col
            key={consultant.id}
            span={isMobile ? 20 : 10}
            justify="center"
            align="middle"
          >
            <Card
              style={cardStyle}
              cover={
                <div
                  style={{
                    padding: "24px",
                    background: "#f0f2f5",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={consultantImages[consultant.id]}
                    alt={consultant.name}
                    style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "60px",
                      objectFit: "cover",
                      margin: "0 auto",
                    }}
                  />
                </div>
              }
            >
              <Typography style={{ marginTop: "-30px" }}>
                <Title level={5}>{consultant.name}</Title>
              </Typography>
              <Typography>
                <Text style={{ textTransform: "capitalize" }} type="secondary">
                  {consultant.expertArea} Specialist
                </Text>
              </Typography>

              <TextArea
                readOnly
                value={consultant.description}
                rows={6}
                maxLength={6}
                style={{ marginTop: "10px" }}
              />

              <Space direction="vertical" style={{ width: "100%" }}>
                <Button
                  type="link"
                  icon={<GlobalOutlined />}
                  href={consultant.website}
                  target="_blank"
                >
                  Visit Website
                </Button>
                <Button
                  type="link"
                  icon={<ContactsOutlined />}
                  href={`tel:${consultant.phone}`}
                >
                  {consultant.phone}
                </Button>
              </Space>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default ConsultantSearch;
